















import { Vue, Component } from 'vue-property-decorator'
import FormBase from '@/shared/classes/form/form-base'
import Field from '@/shared/classes/form/field'
import { FieldTypes } from '@/shared/components/form/field-types'
import { Positions } from '@/shared/interfaces/classes/form.interfaces'
import tokenHelper from '@/shared/helpers/token.helper'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import Form from '@/shared/components/form/Form.vue'
import __ from '@/shared/helpers/__'
import AuthLayout from '@/company/layouts/AuthLayout.vue'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import { CompanyRoutes } from '@/shared/router/company'
import { CompanyActions } from '@/shared/store/company/company.actions'
import Company from '@/shared/modules/company/company.model'

@Component({
  components: {
    AuthLayout,
    Form
  },
  methods: {
    __
  }
})
export default class Login extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint('login')
    .setFields([
      (new Field())
        .setType(FieldTypes.email)
        .setKey('email')
        .setTitle(__('company.views.login.form.email')),
      (new Field())
        .setType(FieldTypes.password)
        .setKey('password')
        .setTitle(__('company.views.login.form.password')),
    ])
    .setInjectValues({
      company_uuid: this.company.uuid,
    })
    .setOnSuccess(this.onSuccess)
    .setSubmit({
      text: __('company.views.login.form.submit'),
      position: Positions.center
    })

  async onSuccess(response: any) {
    tokenHelper.setToken(response.access_token)
    await this.$store.dispatch(AuthActions.me)
      .then(async () => {
        await this.$store.dispatch(CompanyActions.loadCurrentCompany, this.company.slug)
        this.$router.push({ name: CompanyRoutes.index })
      })
  }

  goToRemindPassword(): void {
    this.$router.push({ name: CompanyRoutes.remindPassword })
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
